export default {
  legacy: false,
  locale: 'pl',
  fallbackLocale: 'pl',
  numberFormats: {
    pl: {
      currency: {
        style: 'currency',
        currency: 'PLN',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      },
    },
  } as Record<string, Record<string, Intl.NumberFormatOptions>>,
}
